var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',[_c('b-card-header',[_c('h2',[_c('feather-icon',{attrs:{"icon":"LayersIcon"}}),_vm._v(" "+_vm._s(_vm.$t('Routes.addModel'))+" ")],1)]),_c('b-card-body',[_c('validation-observer',{ref:"refFormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)},"reset":function($event){$event.preventDefault();return _vm.resetForm($event)}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('AppTI.model.name'),"rules":"required|max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('AppTI.model.name'),"label-for":"type"}},[_c('b-form-input',{attrs:{"type":"text","placeholder":"ThinkPad-T5"},model:{value:(_vm.modelData.nombre),callback:function ($$v) {_vm.$set(_vm.modelData, "nombre", $$v)},expression:"modelData.nombre"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('AppTI.model.description'),"rules":"required|max:250"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('AppTI.model.description'),"label-for":"description"}},[_c('b-form-textarea',{attrs:{"id":"description","rows":"3","placeholder":_vm.$t('AppTI.model.descriptionL')},model:{value:(_vm.modelData.descripcion),callback:function ($$v) {_vm.$set(_vm.modelData, "descripcion", $$v)},expression:"modelData.descripcion"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('AppTI.model.brand'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('AppTI.model.brand')}},[_c('v-select',{attrs:{"id":"marcas","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"texto","options":_vm.brands,"reduce":function (option) { return option.id; }},model:{value:(_vm.modelData.tipoMarcaId),callback:function ($$v) {_vm.$set(_vm.modelData, "tipoMarcaId", $$v)},expression:"modelData.tipoMarcaId"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('AppTI.model.type'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('AppTI.model.type')}},[_c('v-select',{attrs:{"id":"marcas","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"texto","options":_vm.typeEquipments,"reduce":function (option) { return option.id; }},model:{value:(_vm.modelData.tipoEquipoId),callback:function ($$v) {_vm.$set(_vm.modelData, "tipoEquipoId", $$v)},expression:"modelData.tipoEquipoId"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1)],1),_c('b-row',{staticClass:"float-right mt-2 mb-2"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-2",attrs:{"variant":"primary","type":"submit"}},[(_vm.addingBrand)?_c('b-spinner',{staticClass:"mr-1",attrs:{"small":""}}):_vm._e(),_vm._v(" "+_vm._s(_vm.$t('Lists.Add'))+" ")],1)],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }